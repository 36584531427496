import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DASHBOARD_TOP_BAR_HEIGHT } from 'utils/constants';

export const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: '100%',
		position: 'absolute',
		top: 0,
		height: DASHBOARD_TOP_BAR_HEIGHT,
		background: theme.palette.common.white,
		zIndex: 5,
	},
	logo: {
		'&:hover': { background: 'transparent' },
	},
	wrapper: {
		display: 'flex',
		height: DASHBOARD_TOP_BAR_HEIGHT,
		margin: '0 auto',
	},
	logo_search_wrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		height: '100%',
		alignItems: 'center',
	},
	search_box_parent: {
		position: 'relative',
		width: '100%',
		display: 'flex',
		justifyContent: 'flex-end',
	},
	search_input: {
		borderRadius: '50px',
		width: '70%',
		background: theme.palette.primary.light,
		paddingLeft: '15px',
	},
	nav_icons__container: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
	nav_icons_user_container: {
		'& .BaseBadge-root': {
			'& .BaseBadge-badge': {
				color: theme.palette.common.white,
				fontWeight: '600',
				zIndex: 'auto',
			},
		},
	},
	toggle: {
		marginLeft: '18px',
	},
	user_icon__span: {
		position: 'absolute',
		top: '-10px',
		left: '15px',
		background: theme.palette.secondary.main,
		color: theme.palette.common.white,
		fontSize: '10px',
		borderRadius: '5px',
		padding: '0 5px',
	},
	search_box: {
		backgroundColor: theme.palette.common.white,
		position: 'absolute',
		top: '50%',
		left: '64%',
		padding: '25px 16px',
		boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05)',
		borderRadius: '0px 0px 24px 24px',
		width: '70%',
		transform: 'translate(-50%, 11%)',
		display: 'none',
	},

	title: {
		color: theme.palette.grey[400],
		fontSize: '15px',
		lineHeight: '46px',
		fontWeight: '400',
		borderRadius: ' 16px',
		padding: '0 15px',
		transition: 'all 0.3s ease',
		cursor: 'pointer',
		'&:hover': {
			background: theme.palette.primary[200],
			color: theme.palette.primary.dark,
			fontWeight: '600',
		},
	},
}));
