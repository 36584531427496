import * as React from 'react';

import {
	Alert,
	Backdrop,
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Fade,
	InputLabel,
	MenuItem,
	Modal,
	Select,
	TextField,
	Typography,
} from '@mui/material';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { ClinicRepoSaga } from '../Clinics/redux/saga';
import {
	reducer as reducerClinic,
	sliceKey as sliceKeyClinic,
} from '../Clinics/redux/slice';
import { reducer, sliceKey } from '../Patients/redux/slice';
import { actions as actionsPromoCode } from './slice';
import { PatientRepoSaga } from '../Patients/redux/saga';
import { useStyles } from '../Clinics/styles';
import {
	selectIsAddPromoCodePopupOpen,
	selectIsEditAction,
	selectLoading,
	selectPromoCodeForm,
	selectSelectedPromoCode,
} from './selectors';
import { PromoCodeForm } from './types';
import { selectSideMenuPractitionersList } from '../Practitioner/redux/selector';

export const PromoCodePopup = () => {
	const unitMeasure = [' ', 'Months', 'Days'];
	useInjectReducer({ key: sliceKey, reducer });
	useInjectSaga({ key: sliceKey, saga: PatientRepoSaga });
	useInjectReducer({ key: sliceKeyClinic, reducer: reducerClinic });
	useInjectSaga({ key: sliceKeyClinic, saga: ClinicRepoSaga });
	useInjectReducer({ key: sliceKeyClinic, reducer: reducerClinic });
	useInjectSaga({ key: sliceKeyClinic, saga: ClinicRepoSaga });
	const styles = useStyles();
	const useSelectedPromoCode = useSelector(selectSelectedPromoCode);
	const form: PromoCodeForm = useSelector(selectPromoCodeForm);
	const useLoading: boolean = useSelector(selectLoading);
	const useIsEditAction: boolean = useSelector(selectIsEditAction);
	const dispatch = useDispatch();
	const isAddPromoCodePopupOpen: boolean = useSelector(
		selectIsAddPromoCodePopupOpen,
	);

	const SideMenuPractitionersList = useSelector(
		selectSideMenuPractitionersList,
	);
	React.useEffect(() => {
		if (useSelectedPromoCode) {
			dispatch(actionsPromoCode.updateFormPromoCodeForEdit());
		}
	}, [useSelectedPromoCode]);
	function handlePopupClose() {
		dispatch(actionsPromoCode.cleanFormPromoCode());
		dispatch(actionsPromoCode.toggleAddPromoCodePopup());
		dispatch(actionsPromoCode.cleanFormPromoSelected());
		dispatch(actionsPromoCode.isEditAction(false));
	}
	const onPromoCodeAddSuccess = () => {
		handlePopupClose();
	};
	const handleFieldChange = (evt: any) => {
		const { name, value } = evt.target;
		dispatch(actionsPromoCode.updateFormPromoCodeValue({ key: name, value }));
	};
	const onSubmitForm = () => {
		try {
			dispatch(
				actionsPromoCode.addPromoCode({
					isHaveFreeTrial: form?.freeTrial,
					callback: onPromoCodeAddSuccess,
				}),
			);
		} catch (e) {
			console.log(e);
		}
	};

	const fields = [
		{
			title: 'Name of package (Display)',
			name: 'packageName',
			placeholder: 'Will show to user',
			value: form?.packageName,
			isSelect: false,
		},
		{
			title: 'Company name',
			name: 'companyName',
			placeholder: 'Will be displayed in the pop-up window',
			value: form?.companyName,
			isSelect: false,
		},
		{
			title: 'Promo code',
			name: 'code',
			placeholder: 'The first letter should be capitalized',
			value: form?.code,
			isSelect: false,
		},
		{
			title: 'Clinic',
			name: 'clinic',
			placeholder: '',
			value: form?.clinic,
			isSelect: true,
			options: SideMenuPractitionersList || [],
		},
		{
			title: 'Plan id',
			name: 'paypalPlanId',
			placeholder: 'Provided to us by PayPal & Stripe',
			value: form?.paypalPlanId,
			isSelect: false,
		},
		{
			title: 'Link to payment',
			name: 'paymentLink',
			placeholder: 'Provided to us by PayPal & Stripe',
			value: form?.paymentLink,
			isSelect: false,
		},
		{
			title: 'Type of payment (Display)',
			name: 'paymentType',
			placeholder: 'Will show to user',
			value: form?.paymentType,
			isSelect: false,
		},
		{
			title: 'Payment period (Display)',
			name: 'periodPayment',
			placeholder: 'Will show to user',
			value: form?.periodPayment,
			isSelect: false,
		},
		{
			title: 'Currency (Display)',
			name: 'currency',
			placeholder: '',
			value: form?.currency,
			isSelect: true,
			options: [
				{ fullName: '', _id: 'p' },
				{ fullName: 'GBP', _id: 'poundSterling' },
				{ fullName: 'EUR', _id: 'euro' },
				{ fullName: 'USD', _id: 'usDollar' },
			],
		},
		{
			title: 'Fee (Display)',
			name: 'fee',
			placeholder: 'Will show to user',
			value: form?.fee,
			isSelect: false,
		},
		{
			title: 'Price per patient per month (Display)',
			name: 'pricePerPatientPerMonth',
			placeholder: 'Will show to user',
			value: form?.pricePerPatientPerMonth,
			isSelect: false,
		},
		{
			title: 'Up-front payment (Display)',
			name: 'upFrontPayment',
			placeholder: 'Will show to user',
			value: form?.upFrontPayment,
			isSelect: false,
		},
		{
			title: 'Payment method',
			name: 'paymentMethod',
			placeholder: '',
			value: form?.paymentMethod || '',
			isSelect: true,
			options: [
				{ fullName: '', _id: 'P' },
				{ fullName: 'Paypal', _id: 'Paypal' },
				{ fullName: 'Stripe', _id: 'Stripe' },
				{ fullName: 'Free', _id: 'Free' },
			],
		},
	];

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={isAddPromoCodePopupOpen}
				onClose={handlePopupClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
				className={clsx(styles.modal)}
			>
				<Fade in={isAddPromoCodePopupOpen}>
					<Box className={clsx(styles.popup)}>
						<Typography
							id="transition-modal-title"
							variant="h6"
							component="h2"
							className={clsx(styles.title)}
						>
							{useIsEditAction ? 'Edit' : 'Add a new'} promo code
						</Typography>
						<Typography id="transition-modal-description" sx={{ mt: 2 }}>
							Register a new promo code, make sure all the details are correct!
						</Typography>
						<Box component="form" style={{ marginTop: '30px' }}>
							{form?.error?.length > 0 && (
								<Alert severity="error">{form.error}</Alert>
							)}

							<Box className={clsx(styles._fieldsContainer)}>
								<Box className={clsx(styles.fieldsContainer)}>
									{fields.map(field =>
										!field.isSelect ? (
											<Box className={clsx(styles.fieldBox)} key={field.name}>
												<InputLabel className={clsx(styles.label)}>
													{field.title}
												</InputLabel>
												<TextField
													className={clsx(styles.input)}
													InputLabelProps={{ style: { color: '#645B70' } }}
													name={field.name}
													fullWidth
													placeholder={field.placeholder}
													id={field.name}
													value={field?.value}
													onChange={e => handleFieldChange(e)}
												/>
											</Box>
										) : (
											<Box className={clsx(styles.fieldBox)} key={field.name}>
												<InputLabel className={clsx(styles.label)}>
													{field.title}
												</InputLabel>
												<Select
													value={field?.value}
													name={field.name}
													id={field.name}
													fullWidth
													onChange={e => handleFieldChange(e)}
													className={clsx(styles.selectInput)}
												>
													{field?.options?.map(clinic => (
														<MenuItem
															value={clinic?.fullName}
															key={clinic?._id}
														>
															{clinic?.fullName}
														</MenuItem>
													))}
												</Select>
											</Box>
										),
									)}
								</Box>

								<Box className={clsx(styles.checkboxContainer)}>
									<Box className={clsx(styles.checkboxBox)}>
										<Checkbox
											className={styles.customCheckbox}
											checked={form?.freeTrial}
											onClick={() =>
												dispatch(
													actionsPromoCode.handleFieldFreeTrialChange(
														!form?.freeTrial,
													),
												)
											}
										/>
										<InputLabel className={clsx(styles.checkboxLabel)}>
											Is there a free trial?
										</InputLabel>
									</Box>
									{form?.freeTrial && (
										<>
											<Box>
												<InputLabel className={clsx(styles.label)}>
													Days / Months
												</InputLabel>
												<Select
													value={
														form?.daysMonths
															? form?.daysMonths
															: useSelectedPromoCode.days_months === 'months'
															? 'Months'
															: 'Days'
													}
													name="daysMonths"
													id="daysMonths"
													fullWidth
													onChange={e => handleFieldChange(e)}
													className={clsx(styles.selectInput)}
												>
													{unitMeasure.map(menu => (
														<MenuItem value={menu} key={menu}>
															{menu}
														</MenuItem>
													))}
												</Select>
											</Box>
											<Box>
												<InputLabel className={clsx(styles.label)}>
													Numbers of days / months
												</InputLabel>
												<TextField
													className={clsx(styles.input)}
													InputLabelProps={{ style: { color: '#645B70' } }}
													name="amount"
													fullWidth
													placeholder="Amount of the trial period"
													type="number"
													id="amount"
													value={form?.amount}
													onChange={e => handleFieldChange(e)}
												/>
											</Box>
										</>
									)}
								</Box>
							</Box>
							<Box display="flex" justifyContent="flex-end" pt={3}>
								<Button
									className={clsx([styles.fbtn, styles.fbtnWhite])}
									variant="contained"
									type="button"
									disabled={useLoading}
									onClick={onSubmitForm}
								>
									{useLoading ? (
										<CircularProgress
											size={24}
											className={clsx(styles.buttonProgress)}
										/>
									) : (
										'Save'
									)}
								</Button>
								<Button
									onClick={() => {
										handlePopupClose();
									}}
									className={clsx(styles.fbtn)}
									variant="text"
								>
									Cancel
								</Button>
							</Box>
						</Box>
					</Box>
				</Fade>
			</Modal>
		</div>
	);
};
