import SearchOutlined from '@mui/icons-material/SearchOutlined';
import {
	Alert,
	Box,
	Button,
	ButtonGroup,
	Container,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { selectActiveClinic } from 'app/containers/Clinics/redux/selector';
import { selectPromoCodeList } from 'app/containers/PromoCodes/selectors';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import {
	IconArchive,
	IconBlackLine,
	IconBlueArchive,
	IconBluePlus,
	IconBlueReassign,
	IconEdit,
	IconSearch,
	IconTrash,
	IconTrashRed,
	emptyStar,
	role,
	star,
} from '../../../../assets/images';
import CustomModal from '../../../components/CustomModal';
import { Search } from '../../../components/Search';
import { SearchAutoComplete } from '../../../components/SearchAutoComplete';
import { ClinicRepoSaga } from '../../Clinics/redux/saga';
import {
	reducer as reducerClinic,
	sliceKey as sliceKeyClinic,
} from '../../Clinics/redux/slice';
import { PractitionerRepoSaga } from '../../Practitioner/redux/saga';
import { actions, reducer, sliceKey } from '../../Practitioner/redux/slice';
import {
	actions as promoCodeActions,
	reducer as reducerPromoCode,
	sliceKey as sliceKeyPromoCode,
} from '../../PromoCodes/slice';
import {
	selectAddPractitionerOpen,
	selectArchivePractitionerOpen,
	selectError,
	selectFrom,
	selectIsEditAction,
	selectPractitionersList,
	selectReAssignedPractitionerId,
	selectSelectedNumOfClients,
	selectSelectedPaymentStatus,
	selectSelectedRole,
	selectselectedId,
	selectselectedName,
} from '../redux/selector';
import { useStyles } from '../styles';
import { PromoCodeRepoSaga } from 'app/containers/PromoCodes/saga';
import { selectClinicId, selectUserType } from 'app/containers/Auth/selectors';
export const Roles = {
	CLINIC_ADMIN: 'CLINIC_ADMIN',
	CLINIC_TEMPLATE: 'CLINIC_TEMPLATE',
	CLINIC_MEMBER: 'CLINIC_MEMBER',
};
const ArchiveTable = ({
	patientPopup,
	setPatientPopup,
	selectedClinicIsVirtual,
}) => {
	const classes = useStyles();
	useInjectReducer({ key: sliceKey, reducer });
	useInjectSaga({ key: sliceKey, saga: PractitionerRepoSaga });
	useInjectReducer({ key: sliceKeyClinic, reducer: reducerClinic });
	useInjectSaga({ key: sliceKeyClinic, saga: ClinicRepoSaga });
	useInjectReducer({ key: sliceKeyPromoCode, reducer: reducerPromoCode });
	useInjectSaga({ key: sliceKeyPromoCode, saga: PromoCodeRepoSaga });
	const userType = useSelector(selectUserType);
	const isAddPractitionerOpen = useSelector(selectAddPractitionerOpen);
	const useClinicId = useSelector(selectClinicId);
	const usePromoCodeList = useSelector(selectPromoCodeList);
	const selectedName = useSelector(selectselectedName);
	const selectedId = useSelector(selectselectedId);
	const useSelectedPaymentStatus = useSelector(selectSelectedPaymentStatus);
	const useSelectedRole = useSelector(selectSelectedRole);
	const useSelectSelectedNumOfClients = useSelector(selectSelectedNumOfClients);
	const isArchivePractitionerOpen = useSelector(selectArchivePractitionerOpen);
	const rows = useSelector(selectPractitionersList);
	const activeClinic = useSelector(selectActiveClinic);
	const dispatch = useDispatch();
	const form = useSelector(selectFrom);
	const PAID_1st = 'PAID_1st';
	const isPractitionerUser =
		userType == '2' || userType === 'PRACTITIONER' ? false : true;
	const practitionersPaymentStatuses = [
		{ key: PAID_1st, value: 'PAID 1st' },
		{ key: 'APPROVED', value: 'Approved' },
		{ key: 'NOT_APPROVED', value: 'Not approved' },
		{ key: 'EXPIRE', value: 'Expire' },
	];
	const practitionersRole = [
		// { key: Roles.CLINIC_ADMIN, value: 'Clinic admin' },
		{ key: Roles.CLINIC_TEMPLATE, value: 'Clinic template' },
		{ key: Roles.CLINIC_MEMBER, value: 'Member' },
	];
	const virtualClinicPaymentStatuses = [
		{ key: 'NOT_SUBSCRIPTION', value: 'Not a free user' },
		{ key: 'PATIENT', value: 'Free user' },
	];
	const numOfClients = Array.from(Array(101).keys()).map(num => num);

	useEffect(() => {
		if (activeClinic?.length > 0) {
			dispatch(actions.searchPractitioners({ searchTerm: '' }));
			dispatch(
				actions.updateFormValue({ key: 'clinic_id', value: activeClinic }),
			);
		}
	}, [activeClinic]);

	useEffect(() => {
		dispatch(promoCodeActions.getPromoCodeList());
	}, []);

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: theme.palette.common,
			color: '#645B70',
			fontWeight: '400',
			background: theme.palette.primary.light,
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 14,
			fontWeight: '500',
		},
	}));

	const StyledTableRow = styled(TableRow)(({ theme }) => ({
		'& .MuiTableCell-root': {
			padding: '10px 20px',
		},
		'&:hover': {
			backgroundColor: '#ece6f4',
			cursor: 'pointer',
			'& .MuiTableCell-root': {
				color: theme.palette.primary.main,
			},
		},
	}));
	const [deletePopup, setDeletePopup] = useState(false);
	const [deletePractitionerPopup, setDeletePractitionerPopup] = useState(false);
	const [approvedPractitionerPopup, setApprovedPractitionerPopup] =
		useState(false);
	const [rolePopup, setRolePopup] = useState(false);
	const [reassignPopup, setReassignPopup] = useState(false);
	const [name, setname] = useState('');
	const [email, setemail] = useState('');
	const [showValidation, setshowValidation] = useState(false);
	const reAssignedPractitionerId = useSelector(selectReAssignedPractitionerId);
	const useIsEditAction = useSelector(selectIsEditAction);
	const useError = useSelector(selectError);

	const ReassignHandleOpen = () => {
		setPatientPopup(true);
	};

	const onPractitionerAddSuccess = () => {
		setPatientPopup(false);
		dispatch(actions.searchPractitionersClinic(''));
		dispatch(actions.resetIsEditAction(false));
	};
	const onPractitionerArchiveSuccess = () => {
		dispatch(actions.searchPractitionersClinic(''));
	};
	const handleFieldChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = evt.target;
		dispatch(actions.updateFormValue({ key: name, value }));
	};
	const handleSelectChange = (evt: any, name) => {
		const { value } = evt.target;
		dispatch(actions.updateFormValue({ key: name, value }));
	};
	const closeAddPractitioner = () => {
		dispatch(actions.updateFormValue({ key: 'fullName', value: '' }));
		dispatch(actions.updateFormValue({ key: 'email', value: '' }));
		dispatch(actions.updateFormValue({ key: 'promoCode', value: '' }));
		dispatch(actions.toggleAddPractitioner());
	};

	const closeArchivePractitioner = () => {
		dispatch(actions.toggleArchivePractitioner());
	};

	const onSubmitForm = () => {
		try {
			if (useIsEditAction) {
				dispatch(
					actions.editPractitioner({ callback: onPractitionerAddSuccess }),
				);
			} else {
				dispatch(
					actions.addPractitioner({ callback: onPractitionerAddSuccess }),
				);
			}
		} catch (e) {
			console.log(e);
		}
	};
	const onArchiveSubmitForm = () => {
		try {
			dispatch(
				actions.updatePractitionerStatus({
					status: 1,
					callback: onPractitionerArchiveSuccess,
				}),
			);
			dispatch(actions.setReAssignPractitionerId({ id: '' }));
		} catch (e) {
			console.log(e);
		}
	};
	const onArchieveCloseForm = () => {
		dispatch(actions.setReAssignPractitionerId({ id: '' }));
	};
	const EditPractitionerHandler = (id: string) => {
		dispatch(actions.resetIsEditAction(true));
		try {
			dispatch(actions.fetchEditPractitioner(id));
		} catch (e) {
			console.log(e);
		}
	};
	const ArchivePractitionerHandler = (id: string, name: string) => {
		try {
			dispatch(actions.toggleArchivePractitioner());
			dispatch(
				actions.setSelectedPractitioner({ selectedId: id, selectedName: name }),
			);
		} catch (e) {
			console.log(e);
		}
	};
	const onPractitionerDeleteSuccess = () => {
		setDeletePractitionerPopup(false);
		dispatch(actions.searchPractitionersClinic(''));
		dispatch(actions.searchPractitioners({ searchTerm: '' }));
	};
	const onPractitionerSetApprovedSuccess = () => {
		setApprovedPractitionerPopup(false);
		dispatch(actions.searchPractitionersClinic(''));
		dispatch(actions.searchPractitioners({ searchTerm: '' }));
		dispatch(actions.setSelectedNumOfClients(1));
	};
	const onPractitionerSetRoleSuccess = () => {
		setApprovedPractitionerPopup(false);
		dispatch(
			isPractitionerUser
				? () => {
						dispatch(actions.searchPractitionersClinic(''));
						dispatch(actions.searchPractitioners({ searchTerm: '' }));
				  }
				: actions.getClinic({ clinicId: useClinicId }),
		);
		setRolePopup(false);
	};
	const handleDeletePatientModel = (id: string, name: string) => {
		dispatch(
			actions.setSelectedPractitioner({ selectedId: id, selectedName: name }),
		);
		setDeletePractitionerPopup(true);
	};
	const handleApprovedPatientModel = (
		id: string,
		name: string,
		paymentStatus: string = '',
		numOfClients?: number,
	) => {
		dispatch(
			actions.setSelectedPractitioner({
				selectedId: id,
				selectedName: name,
				paymentStatus: paymentStatus || '',
				numOfClients: numOfClients,
			}),
		);
		setApprovedPractitionerPopup(true);
	};
	const handleSetRole = (id: string, name: string, role: string = '') => {
		dispatch(
			actions.setSelectedPractitionerRole({
				selectedId: id,
				selectedName: name,
				selectedRole: role || '',
			}),
		);
		setRolePopup(true);
	};
	const deletePractitioner = (practitionerId: string) => {
		dispatch(
			actions.deleteSelectedPractitioner({
				id: practitionerId,
				callback: onPractitionerDeleteSuccess,
			}),
		);
	};
	const setApprovedPractitioner = (
		practitionerId: string,
		paymentStatus?: string | undefined,
		numOfClients?: number,
	) => {
		selectedClinicIsVirtual == 0
			? dispatch(
					actions.setApprovedPractitioner({
						id: practitionerId,
						paymentStatus: paymentStatus || '',
						numOfClients: numOfClients,
						callback: onPractitionerSetApprovedSuccess,
					}),
			  )
			: dispatch(
					actions.setConsumerStatus({
						id: practitionerId,
						paymentStatus: paymentStatus || '',
						callback: onPractitionerSetApprovedSuccess,
					}),
			  );
	};

	const setPractitionerRole = (
		practitionerId: string,
		selectedRole?: string | undefined,
	) => {
		dispatch(
			actions.setPractitionerRole({
				id: practitionerId,
				role: selectedRole || '',
				callback: onPractitionerSetRoleSuccess,
			}),
		);
	};

	const onChangeStatus = e => {
		const { value } = e.target;
		dispatch(actions.setSelectedStatus(value));
	};
	const onChangeRole = e => {
		const { value } = e.target;
		dispatch(actions.setSelectedRole(value));
	};

	const onChangeNumOfClients = e => {
		const { value } = e.target;
		dispatch(actions.setSelectedNumOfClients(value));
	};

	const checkStatus = currentStatus => {
		const res =
			currentStatus === 'NOT_APPROVED' ||
			currentStatus === 'EXPIRE' ||
			!currentStatus;
		return res ? emptyStar : star;
	};

	const formatDate = dateString => {
		if (!dateString) return '-';
		const date = new Date(dateString);
		const day = String(date.getDate()).padStart(2, '0');
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const year = date.getFullYear();

		return `${day}/${month}/${year}`;
	};

	return (
		<Box display="flex" alignItems={'center'}>
			<Container maxWidth={'xl'}>
				<Grid container>
					<Grid item xs={12}>
						<TableContainer>
							<Table
								sx={{ width: 700 }}
								aria-label="simple table"
								style={{ width: '100%', padding: '0' }}
								stickyHeader
							>
								<TableHead>
									<TableRow>
										<StyledTableCell style={{ paddingLeft: '0px' }}>
											{selectedClinicIsVirtual == 0
												? 'Practitioner'
												: 'Consumer'}{' '}
											Name
										</StyledTableCell>
										<StyledTableCell
											align="left"
											style={{ paddingLeft: '15px' }}
										>
											Email
										</StyledTableCell>
										{selectedClinicIsVirtual !== 0 && (
											<StyledTableCell
												align="left"
												style={{ paddingLeft: '15px' }}
											>
												Mobile Number
											</StyledTableCell>
										)}
										<StyledTableCell
											align="left"
											style={{ paddingLeft: '15px' }}
										>
											Payment Status
										</StyledTableCell>
										{selectedClinicIsVirtual === 0 && (
											<StyledTableCell
												align="left"
												style={{ paddingLeft: '15px' }}
											>
												Practitioner Role
											</StyledTableCell>
										)}
										<StyledTableCell
											align="left"
											style={{ paddingLeft: '15px' }}
										>
											Create At
										</StyledTableCell>
										<StyledTableCell align="center" style={{ width: '15%' }}>
											Actions
										</StyledTableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{rows.map((item, index) => (
										<StyledTableRow
											key={index}
											sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
										>
											<StyledTableCell
												style={{ paddingLeft: '0px' }}
												component="th"
												scope="row"
											>
												{item.fullName}
											</StyledTableCell>
											<StyledTableCell
												align="left"
												style={{ paddingLeft: '15px' }}
											>
												{item.email}
											</StyledTableCell>
											{selectedClinicIsVirtual !== 0 && (
												<StyledTableCell
													align="left"
													style={{ paddingLeft: '15px' }}
												>
													{item?.countryCode}
													{item?.mobileNumber}
												</StyledTableCell>
											)}
											<StyledTableCell
												align="left"
												style={{ paddingLeft: '15px' }}
											>
												{selectedClinicIsVirtual == 0
													? item?.paymentStatus
													: item?.subscription === 'PATIENT'
													? 'FREE_USER'
													: item?.subscription}
												{item?.paymentStatus === PAID_1st &&
													` (${item?.details?.number_of_paid_customers || 0})`}
											</StyledTableCell>
											{selectedClinicIsVirtual === 0 && (
												<StyledTableCell
													align="left"
													style={{ paddingLeft: '15px' }}
												>
													{item?.practitionerRole}
												</StyledTableCell>
											)}
											<StyledTableCell
												style={{ paddingLeft: '15px' }}
												component="th"
												scope="row"
											>
												{formatDate(item?.createdAt)}
											</StyledTableCell>
											<StyledTableCell
												align="left"
												sx={{ width: '15%', textAlign: 'center' }}
											>
												<ButtonGroup
													className="iconBtnGroup"
													variant="text"
													aria-label="text button group"
													sx={{ justifyContent: 'flex-end' }}
												>
													{selectedClinicIsVirtual == 0 && (
														<Button onClick={ReassignHandleOpen}>
															<img src={IconBlackLine} alt="Edit" />
														</Button>
													)}
													{selectedClinicIsVirtual == 0 && (
														<Button
															onClick={() => {
																EditPractitionerHandler(item._id);
															}}
														>
															<img src={IconEdit} alt="Edit" />
														</Button>
													)}
													{selectedClinicIsVirtual == 0 && (
														<Button
															onClick={() => {
																ArchivePractitionerHandler(
																	item._id,
																	item.fullName,
																);
															}}
														>
															<img src={IconArchive} alt="Archive" />
														</Button>
													)}
													{selectedClinicIsVirtual == 0 && (
														<Button
															onClick={() =>
																handleDeletePatientModel(
																	item._id,
																	item.fullName,
																)
															}
														>
															<img src={IconTrash} alt="Trash" />
														</Button>
													)}
													<Button
														onClick={() => {
															handleApprovedPatientModel(
																item._id,
																item.fullName,
																item?.paymentStatus,
																item?.details?.number_of_paid_customers,
															);
														}}
													>
														<img
															src={checkStatus(item?.paymentStatus)}
															alt="Approved"
														/>
													</Button>
													{selectedClinicIsVirtual === 0 && (
														<Button
															onClick={() => {
																handleSetRole(
																	item._id,
																	item.fullName,
																	item?.practitionerRole,
																);
															}}
														>
															<img src={role} alt="Role" />
														</Button>
													)}
												</ButtonGroup>
											</StyledTableCell>
										</StyledTableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
				</Grid>
			</Container>

			{/* Archive Popup */}
			<CustomModal
				btnTxt="Reassign"
				openModal={isArchivePractitionerOpen}
				setOpen={closeArchivePractitioner}
				Icon={IconBlueArchive}
				heading="Archive a practitioner "
				secondHeading="You are about  to archive the following practitioner."
				modalClass={[classes.ReassignMClass, classes.archive]}
				disabled={reAssignedPractitionerId?.length > 0 ? false : true}
				onClose={onArchieveCloseForm}
				onSubmit={() => {
					onArchiveSubmitForm();
				}}
			>
				<InputLabel className={classes.label}>Practitioner’s name</InputLabel>
				<TextField
					className={classes.input}
					InputLabelProps={{ style: { color: '#645B70' } }}
					fullWidth
					placeholder="Aurora Johnson"
					id="fullWidth"
					value={selectedName}
					onKeyDown={(e: any) => {
						if (e.key === 'Enter') {
							e.preventDefault();
						}
					}}
				/>
				<Box component={`div`} className={classes.formGroup}>
					<Box component="div" pb={3}>
						<Typography variant="h5">Choose a practitioner</Typography>
						<Typography variant="body1">
							Please reassign associated clients to another practitioner.
						</Typography>
					</Box>
					<SearchAutoComplete
						placeholder="Search archived practitioner..."
						items={rows
							.filter(x => x._id !== selectedId)
							.map(item => ({
								value: item._id,
								label: `${item.fullName} (${item.email})`,
							}))}
						handleOnChange={(e, val) => {
							dispatch(actions.setReAssignPractitionerId({ id: val.value }));
						}}
						endIcon={
							<IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
								<img src={IconSearch} alt={'IconSearch'} />
							</IconButton>
						}
						className={classes.searchInput}
					/>
				</Box>
			</CustomModal>
			{/* ./Archive Popup */}
			{/* Patient Popup */}
			<CustomModal
				btnTxt="Save"
				openModal={isAddPractitionerOpen}
				setOpen={closeAddPractitioner}
				Icon={IconBluePlus}
				heading="Add a new practitioner"
				secondHeading="Register a new practitioner, make sure all the details are correct!"
				modalClass={[classes.patient]}
				onSubmit={() => {
					onSubmitForm();
				}}
			>
				<Box component={`div`} className={classes.formGroup}>
					{form?.error?.length > 0 && (
						<Alert severity="error">{form.error}</Alert>
					)}
					<Box>
						<InputLabel className={classes.label}>
							Practitioner’s full name{' '}
						</InputLabel>
						<TextField
							className={classes.input}
							InputLabelProps={{ style: { color: '#645B70' } }}
							type="text"
							name="fullName"
							fullWidth
							placeholder="Practitioner’s full name"
							id="fullWidth"
							value={form.fullName}
							onChange={handleFieldChange}
							onKeyDown={(e: any) => {
								if (e.key === 'Enter') {
									e.preventDefault();
								}
							}}
						/>
					</Box>
					<Box>
						<InputLabel className={classes.label}>
							Practitioner’s email
						</InputLabel>
						<TextField
							className={classes.input}
							InputLabelProps={{ style: { color: '#645B70' } }}
							InputProps={{
								endAdornment: (
									<SearchOutlined style={{ color: '#938E9C' }}></SearchOutlined>
								),
							}}
							fullWidth
							type="email"
							name="email"
							placeholder="Practitioner’s email"
							id="fullWidth"
							value={form.email}
							onChange={handleFieldChange}
							onKeyDown={(e: any) => {
								if (e.key === 'Enter') {
									e.preventDefault();
								}
							}}
						/>
					</Box>
					<Box>
						<InputLabel className={classes.label}>
							Practitioner’s promo code
						</InputLabel>
						<Select
							value={form?.promoCode}
							name="promoCode"
							id="promoCode"
							fullWidth
							onChange={e => handleSelectChange(e, 'promoCode')}
							className={classes.selectInput}
						>
							{usePromoCodeList?.map(promoCode => (
								<MenuItem value={promoCode?.promo_code} key={promoCode?._id}>
									{promoCode?.promo_code}
								</MenuItem>
							))}
						</Select>
					</Box>
				</Box>
			</CustomModal>
			{/* ./Patient Popup */}
			{/* Reassign Popup */}
			<CustomModal
				btnTxt="Reassign"
				openModal={reassignPopup}
				setOpen={setReassignPopup}
				Icon={IconBlueReassign}
				heading="Reassign"
				secondHeading="Reassign the following client to a different practitioner."
				modalClass={[classes.ReassignMClass]}
				onSubmit={() => {
					if (name?.length === 0 || email?.length === 0) {
						setshowValidation(true);
					} else {
						setshowValidation(false);
						setemail('');
						setname('');
						setPatientPopup(!patientPopup);
					}
				}}
			>
				{showValidation && <Alert severity="error">Please enter values</Alert>}
				<InputLabel className={classes.label}>Client’s name</InputLabel>
				<TextField
					className={classes.input}
					InputLabelProps={{ style: { color: '#645B70' } }}
					fullWidth
					placeholder="Aurora Johnson"
					id="fullWidth"
					value={name}
					onChange={e => {
						setname(e.target.value);
					}}
					onKeyDown={(e: any) => {
						if (e.key === 'Enter') {
							e.preventDefault();
						}
					}}
				/>
				<Box component={`div`} className={classes.formGroup}>
					<Box component="div" pb={3}>
						<Typography variant="h5">Choose a practitioner</Typography>
					</Box>
					<Search
						items={rows}
						placeholder="Search Clients"
						endIcon={
							<IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
								<img src={IconSearch} alt={'IconSearch'} />
							</IconButton>
						}
						className={classes.searchInput}
						value={email}
						onChange={e => {
							setemail(e.target.value);
						}}
					/>
				</Box>
			</CustomModal>
			{/* ./Reassign Popup */}
			{/* Delete Popup */}
			<CustomModal
				btnTxt="Delete"
				openModal={deletePopup}
				setOpen={setDeletePopup}
				Icon={IconTrashRed}
				heading="Delete"
				secondHeading="You are about to delete a client."
				modalClass={[classes.TrashMClass]}
			>
				<InputLabel className={classes.label}>Client’s name</InputLabel>
				<TextField
					className={classes.input}
					InputLabelProps={{ style: { color: '#645B70' } }}
					fullWidth
					placeholder="Aurora Johnson"
					id="fullWidth"
					onKeyDown={(e: any) => {
						if (e.key === 'Enter') {
							e.preventDefault();
						}
					}}
				/>
			</CustomModal>
			<CustomModal
				btnTxt="Delete"
				openModal={deletePractitionerPopup}
				setOpen={setDeletePractitionerPopup}
				Icon={IconTrashRed}
				heading="Delete"
				secondHeading="You are about to delete a practitioner."
				modalClass={[classes.TrashMClass]}
				onSubmit={() => {
					deletePractitioner(selectedId);
				}}
			>
				{useError?.length > 0 && <Alert severity="error">{form.error}</Alert>}
				<InputLabel className={classes.label}>Practitioner’s name</InputLabel>
				<TextField
					className={classes.input}
					InputLabelProps={{ style: { color: '#645B70' } }}
					fullWidth
					placeholder="Aurora Johnson"
					value={selectedName}
					id="full_Width"
					onKeyDown={(e: any) => {
						if (e.key === 'Enter') {
							e.preventDefault();
						}
					}}
				/>
			</CustomModal>
			{/* ./Delete Popup */}
			<CustomModal
				btnTxt="Set status"
				openModal={approvedPractitionerPopup}
				setOpen={setApprovedPractitionerPopup}
				Icon={emptyStar}
				heading="Set status"
				secondHeading={
					selectedClinicIsVirtual == 0
						? 'You are about to set status this practitioner.'
						: 'You are about to set status this consumer.'
				}
				modalClass={[classes.ReassignMClass]}
				onSubmit={() => {
					setApprovedPractitioner(
						selectedId,
						useSelectedPaymentStatus,
						useSelectSelectedNumOfClients,
					);
				}}
			>
				<InputLabel className={classes.label}>
					{selectedClinicIsVirtual == 0
						? 'Practitioner’s name'
						: 'Consumer name'}
				</InputLabel>
				<TextField
					className={classes.input}
					InputLabelProps={{ style: { color: '#645B70' } }}
					fullWidth
					value={selectedName}
					id="full_Width"
					disabled
					onKeyDown={(e: any) => {
						if (e.key === 'Enter') {
							e.preventDefault();
						}
					}}
				/>
				<InputLabel className={classes.label}>
					{selectedClinicIsVirtual == 0
						? 'Practitioner’s status'
						: 'Consumer status'}
				</InputLabel>
				<Select
					value={useSelectedPaymentStatus}
					id={'status'}
					fullWidth
					onChange={e => onChangeStatus(e)}
					className={classes.selectInput}
				>
					{(selectedClinicIsVirtual == 0
						? practitionersPaymentStatuses
						: virtualClinicPaymentStatuses
					).map(paymentStatus => (
						<MenuItem value={paymentStatus.key} key={paymentStatus.key}>
							{paymentStatus.value}
						</MenuItem>
					))}
				</Select>
				{useSelectedPaymentStatus === PAID_1st && (
					<>
						<InputLabel className={classes.label}>
							Number of paid customers
						</InputLabel>
						<Select
							value={useSelectSelectedNumOfClients}
							id={'numOfClients'}
							fullWidth
							onChange={e => onChangeNumOfClients(e)}
							className={classes.selectInput}
						>
							{numOfClients?.map((numOfClient, i) => (
								<MenuItem value={numOfClient} key={i}>
									{numOfClient}
								</MenuItem>
							))}
						</Select>
					</>
				)}
			</CustomModal>
			<CustomModal
				btnTxt="Set role"
				openModal={rolePopup}
				setOpen={setRolePopup}
				Icon={role}
				heading="Set role"
				secondHeading={'You are about to set role this practitioner.'}
				modalClass={[classes.ReassignMClass]}
				onSubmit={() => {
					setPractitionerRole(selectedId, useSelectedRole);
				}}
			>
				<InputLabel className={classes.label}>Practitioner’s name</InputLabel>
				<TextField
					className={classes.input}
					InputLabelProps={{ style: { color: '#645B70' } }}
					fullWidth
					value={selectedName}
					id="full_Width"
					disabled
					onKeyDown={(e: any) => {
						if (e.key === 'Enter') {
							e.preventDefault();
						}
					}}
				/>
				<InputLabel className={classes.label}>Practitioner’s Role</InputLabel>
				<Select
					value={useSelectedRole}
					id={'status'}
					fullWidth
					onChange={e => onChangeRole(e)}
					className={classes.selectInput}
				>
					{practitionersRole.map(role => (
						<MenuItem value={role.key} key={role.key}>
							{role.value}
						</MenuItem>
					))}
				</Select>
			</CustomModal>
		</Box>
	);
};

export default ArchiveTable;
