import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { Button, Container, Grid, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import { Logo } from 'app/components/Logo';
import {
	selectSearchTerm,
	selectUserSidebarOpen,
} from 'app/containers/Patients/redux/selector';
import clsx from 'clsx';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { actions } from '../../containers/Patients/redux/slice';
import WatchTraining from './WatchTraining';
import { useStyles } from './styles';
import { LOGO_STYLE } from '../Header';

interface TopbarProps {
	showWatchTraining?: boolean;
}
const Topbar = ({ showWatchTraining }: TopbarProps) => {
	const styles = useStyles();
	const isSidebarOpen = useSelector(selectUserSidebarOpen);
	const searchTerm = useSelector(selectSearchTerm);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	return (
		<Box className={clsx(styles.root)}>
			<Container maxWidth={'xl'} className={clsx(styles.wrapper)}>
				<Grid container alignItems="center">
					<Grid item xs={9} md={7}>
						<Box className={clsx([styles.logo_search_wrapper])}>
							<Button
								onClick={() => navigate('/')}
								className={clsx([styles.logo])}
							>
								<Logo width={LOGO_STYLE.width} height={LOGO_STYLE.height} />
							</Button>
							<Box className={clsx(styles.search_box_parent)}>
								{/* <Search
									placeholder="Search Clients"
									onChange={e =>
										dispatch(actions.setSearchTerm(e.target.value))
									}
									endIcon={
										<IconButton
											onClick={() =>
												dispatch(actions.searchPatients(searchTerm))
											}
											type="submit"
											sx={{ p: '10px' }}
											aria-label="search"
										>
											<img src={IconSearch} alt={'IconSearch'} />
										</IconButton>
									}
									className={clsx(styles.search_input)}
								></Search> */}
								{/* <Box className={clsx(styles.search_box)}>
                  <Typography className={clsx(styles.title)}>
                    Abigail Smith
                  </Typography>
                  <Typography className={clsx(styles.title)}>
                    Aurora Johnson
                  </Typography>
                  <Typography className={clsx(styles.title)}>
                    Anna Williams
                  </Typography>
                  <Typography className={clsx(styles.title)}>
                    Ariana Brown
                  </Typography>
                  <Typography className={clsx(styles.title)}>
                    Ava Davis
                  </Typography>
                </Box> */}
							</Box>
						</Box>
					</Grid>
					<Grid item xs={3} md={5} justifyContent="flex-end">
						<Box className={clsx(styles.nav_icons__container)}>
							<Box className={clsx(styles.nav_icons_user_container)}>
								{showWatchTraining && <WatchTraining />}
								{/* <IconButton><Badge onClick={() => dispatch(actions.UserToggleMenu())} badgeContent={100} color="secondary"><PersonIcon color="primary" /></Badge></IconButton> */}
								<IconButton
									className={clsx(styles.toggle)}
									onClick={() => dispatch(actions.UserToggleMenu())}
								>
									{isSidebarOpen ? <CloseIcon /> : <MenuIcon />}
								</IconButton>
							</Box>

							{/* <Box className={clsx(styles.nav_icons_user_container)}>
                  <Badge badgeContent={100} color="secondary">
                    <PersonIcon color="primary" />
                  </Badge>
                  <IconButton onClick={() => dispatch(actions.toggleMenu())}>
                    {isSidebarOpen ? <CloseIcon /> : <MenuIcon />}
                  </IconButton>
                </Box> */}
						</Box>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
};

export default Topbar;
