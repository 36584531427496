import {
	Alert,
	Box,
	Button,
	Container,
	Grid,
	InputLabel,
	TextField,
} from '@mui/material';
import SideBar from 'app/components/Sidebar';
import UserRightBar from 'app/components/UserRightBar';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer } from 'redux-injectors';
import { useInjectSaga } from 'utils/redux-injectors';
import Header from '../../../components/Header';
import Content from '../../../components/content';
import { actions as templatesActions } from 'app/containers/Templates/redux/slice';

import { useLocation, useNavigate } from 'react-router-dom';
import TemplateListTable from '../TemplateListTable';
import {
	actions,
	reducer as reducerTemplates,
	sliceKey as sliceKeyTemplates,
} from '../redux/slice';
import { useStyles } from './style';
import CustomModal from 'app/components/CustomModal';
import { IconBluePlus, IconEdit, IconTrashRed } from 'assets/images';
import { TemplatesRepoSaga } from '../redux/saga';
import {
	selectAddTemplateNameError,
	selectAddTemplatePopupOpen,
	selectDeleteTemplatePopupOpen,
	selectEditTemplateError,
	selectEditTemplatePopupOpen,
	selectSelectedTemplate,
} from '../redux/selectors';
import MainHeading from 'app/components/Heading/MainHeading';
import {
	selectPractitionerRole,
	selectUserType,
} from 'app/containers/Auth/selectors';
import { Roles } from 'app/containers/Practitioner/PractitionerTable';
import { TemplatesTypeEnum } from '../redux/types';

const TemplatesList = () => {
	useInjectReducer({ key: sliceKeyTemplates, reducer: reducerTemplates });
	useInjectSaga({ key: sliceKeyTemplates, saga: TemplatesRepoSaga });
	const location = useLocation();
	const { templateType } = location?.state || {};
	const navigate = useNavigate();
	const useSelectedTemplate = useSelector(selectSelectedTemplate);
	const useAddTemplateNameError = useSelector(selectAddTemplateNameError);
	const useEditTemplateError = useSelector(selectEditTemplateError);
	const useAddTemplatePopupOpen = useSelector(selectAddTemplatePopupOpen);
	const useEditTemplatePopupOpen = useSelector(selectEditTemplatePopupOpen);
	const useDeleteTemplatePopupOpen = useSelector(selectDeleteTemplatePopupOpen);
	const [templateName, setTemplateName] = useState('');
	const [editName, setEditName] = useState(useSelectedTemplate?.templateName);
	const userType = useSelector(selectUserType);
	const usePractitionerRole = useSelector(selectPractitionerRole);
	const isPractitionerUser =
		userType === '2' || userType === 'PRACTITIONER' ? false : true;
	useEffect(() => {
		setEditName(useSelectedTemplate?.templateName);
	}, [useSelectedTemplate?.templateName]);

	useEffect(() => {
		callback();
	}, []);

	const dispatch = useDispatch();

	const styles = useStyles();
	const callback = () => {
		dispatch(
			templatesActions.getTemplatesList({
				templateType: templateType,
			}),
		);
	};

	const onSubmitFormAddTemplate = () => {
		dispatch(
			actions.addNewTemplate({
				templateName,
				templateType,
				callback: callback,
			}),
		);
	};
	const onSubmitFormDeleteTemplate = templateId => {
		dispatch(actions.deleteTemplate({ templateId, callback: callback }));
	};
	const handleFieldChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(actions.setAddTemplateError(''));

		const { name, value } = evt.target;
		setTemplateName(value);
	};
	const handleFieldChangeEdit = (evt: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(actions.setEditTemplateNameError(''));
		const { name, value } = evt.target;
		setEditName(value);
	};

	return (
		<Box display="flex" alignItems={'center'}>
			<Header />
			<Content>
				<Grid container spacing={2} className={clsx(styles.root)}>
					<Grid item lg={12} xs={12}>
						<Box display="flex" style={{ height: '100%' }}>
							<SideBar />
							<Container
								maxWidth={'xl'}
								sx={{ height: 'calc( 100vh - 80px )', overflowY: 'scroll' }}
							>
								<Box className={clsx(styles.wrapper)}>
									<Box
										component="div"
										display="flex"
										justifyContent={'space-between'}
										alignItems={'center'}
									>
										{!isPractitionerUser ? (
											<>
												<Box component="div">
													<Button
														className={clsx(styles.backButton)}
														size="large"
														sx={{ borderRadius: '50px', marginLeft: '20px' }}
														onClick={() => {
															navigate('/patients');
														}}
													>
														Back
													</Button>
												</Box>
												<MainHeading
													title={`${
														templateType === TemplatesTypeEnum.CLINIC
															? 'Clinic'
															: 'My'
													} templates`}
												/>
											</>
										) : (
											<Box component="div" />
										)}
										<Box className={clsx(styles.buttonBox)}>
											<Button
												onClick={() => {
													dispatch(actions.setAddTemplatePopupOpen(true));
												}}
												className={clsx(styles.btn)}
												variant="contained"
											>
												Add a new template
											</Button>
										</Box>
									</Box>

									<Box component="div" className={clsx(styles.TableBox)}>
										<TemplateListTable
											setDeleteTemplatePopupOpen={template => {
												dispatch(actions.setDeleteTemplatePopupOpen(true));
												dispatch(actions.setSelectedTemplate(template));
											}}
											setEditTemplatePopupOpen={template => {
												dispatch(actions.setEditTemplatePopupOpen(true));
												dispatch(actions.setSelectedTemplate(template));
											}}
											templateType={templateType}
											setDuplicateTemplateCallback={callback}
										/>
									</Box>
								</Box>
							</Container>
							<UserRightBar />
						</Box>
					</Grid>
				</Grid>
			</Content>
			<CustomModal
				btnTxt="Save"
				openModal={useAddTemplatePopupOpen}
				setOpen={() => {
					dispatch(actions.setAddTemplatePopupOpen(false));
					dispatch(actions.setAddTemplateNameError(''));
					setTemplateName('');
				}}
				Icon={IconBluePlus}
				heading={'Add a new template'}
				secondHeading=""
				modalClass={[styles.patient]}
				onSubmit={onSubmitFormAddTemplate}
				disabled={!Boolean(templateName)}
			>
				<Box component={`div`} className={styles.formGroup}>
					{useAddTemplateNameError && (
						<Alert severity="error">{useAddTemplateNameError}</Alert>
					)}
					<Box>
						<InputLabel className={styles.label}>Template name</InputLabel>
						<TextField
							className={styles.input}
							InputLabelProps={{ style: { color: '#645B70' } }}
							type="name"
							fullWidth
							placeholder="e.g. high blood pressure"
							id="fullWidth1"
							value={templateName}
							onChange={handleFieldChange}
							name="fullName"
							onKeyDown={(e: any) => {
								if (e.key === 'Enter') {
									e.preventDefault();
								}
							}}
						/>
					</Box>
				</Box>
			</CustomModal>
			<CustomModal
				btnTxt="Delete"
				openModal={useDeleteTemplatePopupOpen}
				setOpen={() => {
					dispatch(actions.setDeleteTemplatePopupOpen(false));
					dispatch(actions.setAddTemplateNameError(''));
					setTemplateName('');
				}}
				Icon={IconTrashRed}
				heading={'Delete this template'}
				secondHeading=""
				modalClass={[styles.TrashMClass]}
				onSubmit={() => onSubmitFormDeleteTemplate(useSelectedTemplate?._id)}
			>
				<Box component={`div`} className={styles.formGroup}>
					{useAddTemplateNameError && (
						<Alert severity="error">{useAddTemplateNameError}</Alert>
					)}
					<Box>
						<InputLabel className={styles.label}>Template name</InputLabel>
						<TextField
							className={styles.input}
							InputLabelProps={{ style: { color: '#645B70' } }}
							type="name"
							fullWidth
							id="fullWidth2"
							value={useSelectedTemplate?.templateName}
							name="fullName"
							disabled={!Boolean(useSelectedTemplate?.templateName)}
							onKeyDown={(e: any) => {
								if (e.key === 'Enter') {
									e.preventDefault();
								}
							}}
						/>
					</Box>
				</Box>
			</CustomModal>
			<CustomModal
				btnTxt="Save"
				openModal={useEditTemplatePopupOpen}
				setOpen={() => {
					dispatch(actions.setEditTemplatePopupOpen(false));
					dispatch(actions.setEditTemplateNameError(''));
				}}
				Icon={IconEdit}
				heading="Edit"
				secondHeading="Choose a name for the template"
				modalClass={styles.EditPopup}
				disabled={!Boolean(editName)}
				onSubmit={() => {
					dispatch(
						actions.editTemplateName({
							_id: useSelectedTemplate?._id,
							templateName: editName,
							callback: callback,
						}),
					);
				}}
			>
				<Box component={`div`} className={styles.formGroup}>
					{useEditTemplateError && (
						<Alert severity="error">{useEditTemplateError}</Alert>
					)}
					<TextField
						className={styles.input}
						InputLabelProps={{ style: { color: '#645B70' } }}
						fullWidth
						placeholder="e.g. high blood pressure"
						id="fullWidth"
						value={editName}
						onChange={handleFieldChangeEdit}
						onKeyDown={(e: any) => {
							if (e.key === 'Enter') {
								e.preventDefault();
							}
						}}
					/>
				</Box>
			</CustomModal>
		</Box>
	);
};

export default TemplatesList;
