import React from 'react';

import { Box } from '@mui/material';

import { useStyles } from './styles';
import clsx from 'clsx';

const Content = ({ children }) => {
	const styles = useStyles();
	return <Box className={clsx(styles.root)}>{children}</Box>;
};

export default Content;
