import {
	Alert,
	Box,
	Button,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useStyles } from './style';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import CustomModal from 'app/components/CustomModal';
import { IconBluePlus } from 'assets/images';
import {
	selectAddTemplateError,
	selectAddTemplateStatus,
} from '../redux/selectors';
import { actions } from '../redux/slice';
import { TemplatesTypeEnum } from '../redux/types';
import { selectPractitionerRole } from 'app/containers/Auth/selectors';
import { Roles } from 'app/containers/Practitioner/PractitionerTable';

export function SaveAsTemplate({ handleSaveTemplate }) {
	const styles = useStyles();
	const dispatch = useDispatch();
	const useAddTemplateError = useSelector(selectAddTemplateError);
	const useAddTemplateStatus = useSelector(selectAddTemplateStatus);
	const [openSaveTemplateName, setOpenSaveTemplateName] = useState(false);
	const [templateName, setTemplateName] = useState('');
	const usePractitionerRole = useSelector(selectPractitionerRole);

	const optionsTemplateType = [
		{
			displayText: 'My template',
			value: TemplatesTypeEnum.PRIVATE,
		},
		{
			displayText: 'Clinic template',
			value: TemplatesTypeEnum.CLINIC,
		},
	];
	const [templateType, setTemplateType] = useState<any>(
		optionsTemplateType[0].value,
	);
	const handleFieldChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = evt.target;
		setTemplateName(value);
	};
	useEffect(() => {
		if (useAddTemplateStatus) {
			setOpenSaveTemplateName(false);
		}
	}, [useAddTemplateStatus]);

	return (
		<Box className={clsx(styles.root)}>
			<Button
				className={clsx(styles.button)}
				onClick={() => {
					dispatch(actions.setAddTemplateError(''));
					dispatch(actions.setAddTemplateStatus(''));
					setOpenSaveTemplateName(true);
				}}
			>
				Save as template
			</Button>
			<CustomModal
				btnTxt="Save"
				openModal={openSaveTemplateName}
				onSubmit={() => handleSaveTemplate(templateName, templateType)}
				setOpen={() => {
					setOpenSaveTemplateName(false);
				}}
				Icon={IconBluePlus}
				heading="Save as template"
				secondHeading=""
				modalClass={[styles.saveNameMClass]}
				disabled={!Boolean(templateName)}
			>
				<Box>
					<InputLabel className={styles.label}>Template name</InputLabel>
					<TextField
						className={styles.input}
						InputLabelProps={{ style: { color: '#645B70' } }}
						type="name"
						fullWidth
						placeholder="e.g. high blood pressure"
						id="fullWidth"
						value={templateName}
						onChange={handleFieldChange}
						name="fullName"
						onKeyDown={(e: any) => {
							if (e.key === 'Enter') {
								e.preventDefault();
							}
						}}
					/>
					{useAddTemplateError.length > 0 && (
						<Alert severity="error">{useAddTemplateError}</Alert>
					)}
					{useAddTemplateStatus && (
						<Alert severity="success">{useAddTemplateStatus}</Alert>
					)}
					{(usePractitionerRole === Roles.CLINIC_TEMPLATE ||
						usePractitionerRole === Roles.CLINIC_ADMIN) && (
						<>
							<InputLabel className={styles.label}>Template type</InputLabel>
							<Select
								value={templateType}
								name="templateType"
								id="templateType"
								fullWidth
								onChange={e => setTemplateType(e.target.value)}
								className={styles.selectInput}
							>
								{optionsTemplateType?.map(option => (
									<MenuItem value={option?.value} key={option?.value}>
										{option?.displayText}
									</MenuItem>
								))}
							</Select>
						</>
					)}
				</Box>
			</CustomModal>
		</Box>
	);
}
