import { Box, Button, Grid } from '@mui/material';
import React, { useState } from 'react';
import ExplanatoryNote from '../ExplanatoryNote';
import Heading from 'app/components/Heading';
import { DASHBOARD_TOP_BAR_HEIGHT } from 'utils/constants';
import NutrientSlider from '../NutrientSlider';
import VitaminMineralsBox from '../VitaminMineralsBox';
import {
	selectIngredientSearchTerm,
	selectIngredientsList,
	selectNutrientAndVitaminDetails,
} from 'app/containers/Patients/redux/selector';
import { useSelector } from 'react-redux';
import AutocompleteBox from '../AutocompleteBox';
import { IngredientSelection } from '../Autocomplete';
export const SAVE_PROFILE_BUTTON_TEXT = 'Save profile';
export const SAVE_TEMPLATE_BUTTON_TEXT = 'Save template';
interface ContainerProps {
	nutrients: any;
	setNutrients: any;
	vitaminMinerals: any;
	setVitaminMinerals: any;
	setPractitionerExcluded: any;
	practitionerExcluded: any;
	setPractitionerIncluded: any;
	practitionerIncluded: any;
	setPractitionerIngredientsWarning: any;
	practitionerIngredientsWarning: any;
	restrictions: any;
	setRestrictions: any;
	userVitamins: any;
	setUserVitamins: any;
	handleSave: any;
	handleCancelButton: any;
	saveButtonText: string;
}

const DataContainer = ({
	nutrients,
	setNutrients,
	vitaminMinerals,
	setVitaminMinerals,
	setPractitionerExcluded,
	practitionerExcluded,
	setPractitionerIncluded,
	practitionerIncluded,
	setPractitionerIngredientsWarning,
	practitionerIngredientsWarning,
	restrictions,
	setRestrictions,
	userVitamins,
	setUserVitamins,
	handleSave,
	handleCancelButton,
	saveButtonText,
}: ContainerProps) => {
	const VITAMIN_SORT_ID = 60000;

	const nutrientsToRender = [1005, 1004, 1003, 1008];
	const nutrientAndVitaminDetails = useSelector(
		selectNutrientAndVitaminDetails,
	);
	const ingredientsList: any[] = useSelector(selectIngredientsList);
	const ingredientSearchTerm = useSelector(selectIngredientSearchTerm);
	const [typeIngredientSelection, setTypeIngredientSelection] =
		useState<IngredientSelection>(null);
	const [isWarningTypeCategories, setIsWarningTypeCategories] =
		React.useState(false);

	const vitaminMineralsBoxData = [
		{
			vitaminMinerals: vitaminMinerals.filter(
				item => item?.sortOrder >= VITAMIN_SORT_ID,
			),
			title: 'Vitamins',
		},
		{
			vitaminMinerals: vitaminMinerals.filter(
				item => item?.sortOrder < VITAMIN_SORT_ID,
			),
			title: 'Minerals',
		},
	];
	const autocompleteData = [
		{
			setPractitionerIngredients: setPractitionerExcluded,
			practitionerIngredients: practitionerExcluded,
			autocompleteType: 'Exclude',
			title: 'Exclude',
			subtitle: 'Foods to avoid',
			infoText: `Recipes containing ingredients from this list will not be presented in the client's App`,
		},
		{
			setPractitionerIngredients: setPractitionerIngredientsWarning,
			practitionerIngredients: practitionerIngredientsWarning,
			autocompleteType: 'Warning',
			title: 'Reduce / Warning',
			subtitle: 'Things to minimize',
			infoText:
				'Recipes containing ingredients from this list will be presented with a recommendation to reduce quantities',
			setIsWarningTypeCategories: setIsWarningTypeCategories,
			isWarningTypeCategories: isWarningTypeCategories,
			setRestrictions: setRestrictions,
		},
		{
			setPractitionerIngredients: setPractitionerIncluded,
			practitionerIngredients: practitionerIncluded,
			autocompleteType: 'Include',
			title: 'Include',
			subtitle: 'Recommended foods',
			infoText: `Ingredient preferences filter your recipe feed impacting the recipe's match score`,
		},
	];
	return (
		<>
			<Box style={{ marginTop: 0.5 * DASHBOARD_TOP_BAR_HEIGHT }}>
				<Grid container spacing={2}>
					<ExplanatoryNote />
					<Grid item xs={12} md={6} lg={6} xl={6}>
						<Heading title="Macronutrients" />
						<Box>
							<Grid container spacing={2}>
								{nutrients.map(
									(element, index) =>
										nutrientsToRender.includes(element?.optionId) && (
											<Grid item xs={12} md={12} xl={8} key={element.optionId}>
												<NutrientSlider
													key={element.optionText}
													element={element}
													index={index}
													nutrients={nutrients}
													setNutrients={setNutrients}
													setVitaminMinerals={setVitaminMinerals}
													vitaminMinerals={vitaminMinerals}
													nutrientAndVitaminDetails={nutrientAndVitaminDetails}
													allVitaminMinerals={vitaminMinerals}
												/>
											</Grid>
										),
								)}
							</Grid>
						</Box>
					</Grid>
					{vitaminMineralsBoxData.map((data, i) => (
						<VitaminMineralsBox
							allVitaminMinerals={vitaminMinerals}
							vitaminMinerals={data.vitaminMinerals}
							title={data.title}
							nutrients={nutrients}
							setNutrients={setNutrients}
							setVitaminMinerals={setVitaminMinerals}
							nutrientAndVitaminDetails={nutrientAndVitaminDetails}
							setUserVitamins={setUserVitamins}
							userVitamins={userVitamins}
							key={i}
						/>
					))}
				</Grid>
			</Box>
			<Box style={{ marginTop: '25px' }}>
				<Grid container spacing={2}>
					{autocompleteData.map((data: any, i) => (
						<AutocompleteBox
							key={i}
							practitionerExcluded={practitionerExcluded}
							practitionerIncluded={practitionerIncluded}
							practitionerIngredientsWarning={practitionerIngredientsWarning}
							ingredientsList={ingredientsList}
							ingredientSearchTerm={ingredientSearchTerm}
							setPractitionerIngredients={data.setPractitionerIngredients}
							practitionerIngredients={data.practitionerIngredients}
							autocompleteType={data.autocompleteType}
							title={data.title}
							subtitle={data.subtitle}
							setIsWarningTypeCategories={data.setIsWarningTypeCategories}
							isWarningTypeCategories={data.isWarningTypeCategories}
							restrictions={restrictions}
							setRestrictions={data.setRestrictions}
							infoText={data.infoText}
							typeIngredientSelection={typeIngredientSelection}
							setTypeIngredientSelection={setTypeIngredientSelection}
						/>
					))}
				</Grid>
			</Box>
			<Grid style={{ marginTop: '50px' }}>
				<Button
					style={{
						float: 'right',
						backgroundColor: 'transparent',
						color: '#938E9C',
						marginBottom: '50px',
						textDecorationLine: 'underline',
					}}
					size="large"
					sx={{ borderRadius: '50px', marginLeft: '10px' }}
					onClick={handleCancelButton}
				>
					Cancel
				</Button>
				<Button
					style={{
						float: 'right',
						backgroundColor: '#3A1086',
						color: '#fff',
						marginBottom: '50px',
						padding: '8px 16px',
					}}
					size="large"
					sx={{ borderRadius: '50px' }}
					onClick={handleSave}
				>
					{saveButtonText}
				</Button>
			</Grid>
		</>
	);
};

export default DataContainer;
