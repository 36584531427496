import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import {
	Badge,
	Button,
	Container,
	Grid,
	IconButton,
	Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { Logo } from 'app/components/Logo';
import { Search } from 'app/components/Search';
import {
	selectSearchTerm,
	selectSidebarOpen,
	selectUserSidebarOpen,
} from 'app/containers/Patients/redux/selector';
import clsx from 'clsx';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { actions } from '../../containers/Patients/redux/slice';
import { useStyles } from './styles';
export const LOGO_STYLE = { width: 93, height: 25 };
const Header = () => {
	const styles = useStyles();
	const isSidebarOpen = useSelector(selectUserSidebarOpen);
	const searchTerm = useSelector(selectSearchTerm);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	return (
		<Box className={clsx(styles.root)}>
			<Container maxWidth={'xl'} className={clsx(styles.wrapper)}>
				<Grid container spacing={2} alignItems="center">
					<Grid item xs={9} md={7}>
						<Box className={clsx([styles.logo_search_wrapper])}>
							<Button
								onClick={() => navigate('/')}
								className={clsx([styles.logo])}
							>
								<Logo width={LOGO_STYLE.width} height={LOGO_STYLE.height} />
							</Button>
							<Box className={clsx(styles.search_box_parent)}>
								<Box className={clsx(styles.search_box)}>
									<Typography className={clsx(styles.title)}>
										Abigail Smith
									</Typography>
									<Typography className={clsx(styles.title)}>
										Aurora Johnson
									</Typography>
									<Typography className={clsx(styles.title)}>
										Anna Williams
									</Typography>
									<Typography className={clsx(styles.title)}>
										Ariana Brown
									</Typography>
									<Typography className={clsx(styles.title)}>
										Ava Davis
									</Typography>
								</Box>
							</Box>
						</Box>
					</Grid>
					<Grid item xs={3} md={5} justifyContent="flex-end">
						<Box className={clsx(styles.nav_icons__container)}>
							<Box className={clsx(styles.nav_icons_user_container)}>
								{/* <IconButton><Badge onClick={() => dispatch(actions.UserToggleMenu())} badgeContent={100} color="secondary"><PersonIcon color="primary" /></Badge></IconButton> */}
								<IconButton
									className={clsx(styles.toggle)}
									onClick={() => dispatch(actions.UserToggleMenu())}
								>
									{isSidebarOpen ? <CloseIcon /> : <MenuIcon />}
								</IconButton>
							</Box>

							{/* <Box className={clsx(styles.nav_icons_user_container)}>
                  <Badge badgeContent={100} color="secondary">
                    <PersonIcon color="primary" />
                  </Badge>
                  <IconButton onClick={() => dispatch(actions.toggleMenu())}>
                    {isSidebarOpen ? <CloseIcon /> : <MenuIcon />}
                  </IconButton>
                </Box> */}
						</Box>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
};

export default Header;
