import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { DASHBOARD_TOP_BAR_HEIGHT } from 'utils/constants';

export const useStyles = makeStyles((theme: Theme) => ({
	root: {
		height: 'auto',
		width: '100vw',
		background: '#F9F9F9',
		paddingTop: DASHBOARD_TOP_BAR_HEIGHT,
	},
}));
